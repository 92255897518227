import React from 'react'
import cn from 'classnames'
import './typography.css'

const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  subheading1: 'h4',
  subheading2: 'h4',
  body1: 'p',
  body2: 'p',
  bodyHighlighted: 'span',
}

const Typography = ({ variant, color, children, ...props }) => {
  const Component = variant ? variantsMapping[variant] : 'p'

  return (
    <Component
      className={cn({
        [`typography--variant-${variant}`]: variant,
        [`typography--color-${color}`]: color,
      })}
      {...props}
    >
      {children}
    </Component>
  )
}

export default Typography
