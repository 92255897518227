import './App.css'
import Experience from './components/sections/experience'
import Footer from './components/sections/footer'
import Intro from './components/sections/intro'
import Quote from './components/sections/quote'
import Skills from './components/sections/skills'

function App() {
  return (
    <>
      <div className='App'>
        <Intro />
        <Quote />
        <Experience />
        <Skills />
      </div>
      <Footer />
    </>
  )
}

export default App
