/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import Typography from '../typography'

const Skills = () => {
  const skills = [
    {
      type: 'Soft Skills',
      items: [
        'Teamwork',
        'Communication',
        'Problem solving',
        'Time management',
        'Leadership',
        'Creativity',
        'Adaptability',
        'Organization',
      ],
    },
    {
      type: 'Management',
      items: [
        'Agile project management methodologies (Scrum, Kanban)',
        'Time management and prioritization',
        'Project Planning and Scheduling',
        'Risk management',
        'Team management',
        'Client management',
      ],
    },
    {
      type: 'Design',
      items: [
        'Wireframing and prototyping',
        'User research and testing',
        'Information architecture',
        'Interaction design',
        'Visual design',
        'Graphic design',
        'Motion design',
        'Video editing',
      ],
    },
    {
      type: 'Development',
      items: ['HTML/CSS', 'JavaScript', 'React', 'TypeScript', 'PHP', 'Wordpress', 'Docker', 'MySQL', 'REST', 'Jest'],
    },
    {
      type: 'Tools',
      items: [
        'Adobe XD',
        'Adobe Photoshop',
        'Adobe Illustrator',
        'Adobe After Effects',
        'Adobe Premiere Pro',
        'Miro',
        'Postman',
        'Jira',
        'Slack',
        'Git',
      ],
    },
    {
      type: 'Languages',
      items: ['Spanish: Native', 'English: Fluent'],
    },
    {
      type: 'Formal Education',
      items: ['Bachelor of Science in Computer Science', 'Universidad Siglo 21, Argentina', '2014 - 2019'],
    },
  ]

  const colors = ['blue', 'green', 'yellow', 'red', 'purple', 'lime']

  return (
    <section className='skills container'>
      <Typography variant='h3' color='primary' style={{ textAlign: 'center' }}>
        My Skills
      </Typography>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry>
          {skills.map((skill, index) => {
            return (
              <div className={`skill card bg__${colors[Math.floor(Math.random() * colors.length)]}`} key={index}>
                <Typography variant='body1'>{skill.type}</Typography>
                <ul>
                  {skill.items.map((item, index) => {
                    return <li key={index}>{item}</li>
                  })}
                </ul>
              </div>
            )
          })}
        </Masonry>
      </ResponsiveMasonry>
    </section>
  )
}

export default Skills
