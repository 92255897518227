import React, { useRef } from 'react'
import Typography from '../typography'
import Button from '../ui/button'

const Intro = () => {
  const headerRef = useRef(null)

  const scrollToHeader = () => {
    headerRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      {/* Intro */}
      <section className='intro container grid__2_columns'>
        <div className='intro__content'>
          <Typography variant='h1' color='primary'>
            Hi! I'm Giselle Craig
          </Typography>
          <Typography variant='subheading1' color='primary' style={{ marginTop: '10px' }}>
            designer - developer - manager
          </Typography>
          <Typography variant='subheading2' color='secondary' style={{ marginTop: '2rem' }}>
            I’m a <Typography variant='bodyHighlighted'>passionate technologist</Typography> with{' '}
            <Typography variant='bodyHighlighted'>over 10 years experience</Typography> delivering successful projects
            for notable organizations
          </Typography>
          <Button className='button button--primary card' onClick={scrollToHeader}>
            Read more about me
          </Button>
        </div>
        <div className='intro__image'>
          <img src='img/giselle-01.png' alt='Giselle Craig' />
        </div>
      </section>

      {/* Who I am */}
      <section id='whoIam' className='whoIam container' ref={headerRef}>
        <Typography variant='h3' color='primary' style={{ marginTop: '10px', textAlign: 'center' }}>
          Who I am
        </Typography>

        <div className='grid__2_columns'>
          <div className='whoIam__left'>
            <Typography variant='body1' color='secondary' style={{ marginTop: '2rem' }}>
              I’m a <Typography variant='bodyHighlighted'>results-driven professional</Typography> with a passion for
              technology, design, and development, stemming from a young age when{' '}
              <Typography variant='bodyHighlighted'>I first started</Typography> learning graphic design{' '}
              <Typography variant='bodyHighlighted'>at the age of 12.</Typography>
              <br />
              <br />
              My <Typography variant='bodyHighlighted'>hands-on experience</Typography>, combined with a{' '}
              <Typography variant='bodyHighlighted'>Bachelor in Computer Science</Typography> and{' '}
              <Typography variant='bodyHighlighted'>natural curiosity for new tech and challenges</Typography>, has
              enabled me to excel in areas such as{' '}
              <Typography variant='bodyHighlighted'>multimedia and UX/UI design and web development</Typography>,
              leading me to become a <Typography variant='bodyHighlighted'>team leader and project manager</Typography>.
            </Typography>
          </div>
          <div className='whoIam__right'>
            <Typography variant='body1' color='secondary' style={{ marginTop: '2rem' }}>
              I have a strong track record of{' '}
              <Typography variant='bodyHighlighted'>
                delivering successful projects for renowned organizations
              </Typography>{' '}
              such as <Typography variant='bodyHighlighted'>Electronic Arts</Typography> to web3 start-ups like{' '}
              <Typography variant='bodyHighlighted'>Genies</Typography>, as well as for local institutions such as the{' '}
              <Typography variant='bodyHighlighted'>Education Ministry of Corrientes</Typography> and{' '}
              <Typography variant='bodyHighlighted'>Universidad Nacional del Nordeste</Typography> in Argentina.
              <br />
              <br />
              I’m a <Typography variant='bodyHighlighted'>team player</Typography>, and confident that{' '}
              <Typography variant='bodyHighlighted'>
                my practical expertise and experience can be a valuable asset to any project team
              </Typography>
              , bringing a hands-on approach, real-life experience, and a passion for technology and design to the
              table.
            </Typography>
          </div>
        </div>
        <a href='/gisellecraig-cv-2023.pdf' target='_blank' rel='noopener noreferrer'>
          <Button className='button button--primary card'>Download my CV</Button>
        </a>
      </section>
    </>
  )
}

export default Intro
