import React from 'react'
import Typography from '../typography'
import data from './experience.json'

const Quote = () => {
  return (
    <>
      <section className='quote'>
        <div className='container'>
          <Typography variant='body1' color='tertiary' style={{ marginBottom: '10px' }}>
            {data[0].projects[0].more?.quote?.content}
          </Typography>
          <div className='author' style={{ padding: '10px 0' }}>
            <Typography
              variant='body1'
              color='tertiary'
              style={{ fontWeight: 'bold', display: 'inline-block', verticalAlign: 'middle', marginTop: '0' }}
            >
              {data[0].projects[0].more?.quote?.author}
              <br />
              {data[0].projects[0].company}
            </Typography>
            <img
              src={data[0].projects[0].more?.quote?.authorPhoto}
              alt={data[0].projects[0].more?.quote?.author}
              style={{
                maxWidth: '80px',
                borderRadius: '50px',
                marginRight: '10px',
                display: 'block',
                margin: '0 auto',
                fontWeight: 'bold',
                verticalAlign: 'middle',
                border: '2px solid rgb(115 185 206)',
              }}
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default Quote
