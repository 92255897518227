// Create a button component that will render a <button> tag with some styles and will render a <Link> tag if the to prop is passed
import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({ children, to, ...props }) => {
  const Tag = to ? Link : 'button'

  return (
    <Tag className='button' to={to} {...props}>
      {children}
    </Tag>
  )
}

export default Button
