import React from 'react'
import Typography from '../typography'

const Footer = () => {
  return (
    <>
      <footer>
        <div className='footer__content'>
          <Typography variant='h1' color='tertiary'>
            Let's connect!
          </Typography>
          <Typography variant='subheading1' color='tertiary' style={{ marginTop: '10px' }}>
            <a href='mailto:gisellecraig@gmail.com' _target='blank'>
              gisellecraig@gmail.com
            </a>
          </Typography>
          <Typography variant='subheading1' color='tertiary' style={{ marginTop: '10px' }}>
            <a href='https://www.linkedin.com/in/gisellecraig/' _target='blank'>
              LinkedIn
            </a>
          </Typography>
          <Typography variant='subheading1' color='tertiary' style={{ marginTop: '10px' }}>
            <a href='https://www.behance.net/gcraig' _target='blank'>
              Behance
            </a>
          </Typography>
        </div>
      </footer>
    </>
  )
}

export default Footer
