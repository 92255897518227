import React from 'react'
import Typography from '../typography'

const Experience = () => {
  return (
    <section className='experience container' style={{ marginBottom: '10rem' }}>
      <Typography variant='h3' color='primary' style={{ textAlign: 'center' }}>
        Companies I've Worked With
      </Typography>

      <div className='logos'>
        <div className='logo geniesLogo'>
          <img alt='Genies Logo' src='img/genies-logo.png' />
        </div>
        <div className='logo eaLogo'>
          <img alt='Electronic Arts Logo' src='img/ea-logo.png' />
        </div>
        <div className='logo mateLogo'>
          <img alt='42mate Logo' src='img/42mate-logo.png' />
        </div>
        <div className='logo mecLogo'>
          <img alt='Ministerio de Educación de Corrientes Logo' src='img/mec-logo.png' />
        </div>
        <div className='logo medLogo'>
          <img alt='Facultad de Medicina, Universidad Nacional del Nordeste Logo' src='img/med-logo.png' />
        </div>
      </div>
    </section>
  )
}

export default Experience
